import { useState, useEffect } from "react";
import { useMyContext } from "../context/store";
import ContentEditor from "./ContentEditor";
import { useParams } from "react-router-dom";
import {
  graphcms,
  UPDATE_POST,
  PUBLISH_POST,
  UPDATE_POST_CATEGORY,
  REMOVE_POST_CATEGORY,
  CREATE_CATEGORY,
  PUBLISH_CATEGORY,
} from "../Graphql/Mutations";

import { QUERY_CATEGORIES } from "../Graphql/Queries";
import slugify from "react-slugify";

const ArticleForm = ({ post }) => {
  const { slug } = useParams();
  const { token, handleEditToggle, reload, setReload, setLoading } =
    useMyContext();
  const [text, setText] = useState(
    post.text || "<p>There is no Text here!</p>"
  );
  const [title, setTitle] = useState(post.title || "");
  const [description, setDescription] = useState(post.description || "");
  const [imgUrl, setImgUrl] = useState(post.imgUrl || "");
  const [featuredPost, setFeaturedPost] = useState(post.featuredPost || false);
  const [status, setStatus] = useState("");
  const [colourOptions, setColourOptions] = useState([]);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState(post.categories || []);
  const [options, setOptions] = useState([]);
  const initCat = { name: "", slug: "", color: "" };
  const [newCat, setNewCat] = useState(initCat);
  const [myState, setMyState] = useState("");

  useEffect(() => {
    setCategories(post.categories);
    graphcms
      .request(QUERY_CATEGORIES)
      .then((res) => {
        const optionArr = res.categories.map(function (obj) {
          const postCat = {};
          postCat["label"] = obj["name"]; // Assign new key
          postCat["value"] = obj["slug"]; // Assign new key
          postCat["color"] = obj["color"]["hex"]; // Assign new key
          return postCat;
        });

        setColourOptions(optionArr);
      })
      .catch((errors) => {
        setError(errors);
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const notUsedCat = colourOptions.filter(
      (notUsed) => !post.categories.some((i) => i.slug === notUsed.value)
    );
    setOptions(notUsedCat);
    // eslint-disable-next-line
  }, [colourOptions]);

  const obj = {
    id: post.id,
    title: title,
    slug: slug,
    text: text,
    description: description,
    verified: false,
    imgUrl: imgUrl,
    featuredPost: featuredPost,
  };
  async function handleUpdate(id) {
    setStatus("loading");
    await graphcms.request(UPDATE_POST, obj);
    await graphcms.request(PUBLISH_POST, { id: id }).then((res) => {
      setStatus("success");
      handleEditToggle();
    }).catch((error) => console.log(error));
  }
  async function handleUpdateCat(id, slug) {
    setLoading(true);
    const response = await graphcms.request(UPDATE_POST_CATEGORY, {
      id: id,
      slug: slug,
    });
    await graphcms.request(PUBLISH_POST, { id: id });
    setMyState(response);
    setLoading(false);
    setReload(!reload);
  }
  async function handleDeleteCat(id, slug) {
    setLoading(true);
    const response = await graphcms.request(REMOVE_POST_CATEGORY, {
      id: id,
      slug: slug,
    });
    await graphcms.request(PUBLISH_POST, { id: id });
    setMyState(response);
    setLoading(false);
    setReload(!reload);
  }
  async function handleNewCat(e) {
    e.preventDefault();
    setError("");
    const newCatMod = {
      name: newCat.name,
      slug: slugify(newCat.name),
      color: { hex: newCat.color },
    };

    try {
      const { createCategory } = await graphcms.request(
        CREATE_CATEGORY,
        newCatMod
      );
      await graphcms.request(PUBLISH_CATEGORY, { id: createCategory?.id });
      console.log(createCategory);
      setMyState(createCategory);
    } catch (error) {
      console.error("Error fetching data:", error.response.errors[0].message);
      setError(error.response.errors[0].message);
    }

    setReload(!reload);
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setNewCat((prevInputs) => ({
      ...prevInputs,
      [name]: value,
      slug: slugify(newCat.name) || "",
    }));
  }
  return (
    <div key={myState}>
      <h2>{status}</h2>
      <label htmlFor="title">Title</label>
      <input
        type="text"
        id="title"
        value={title}
        className="title"
        onChange={(e) => setTitle(e.target.value)}
      />
      <label htmlFor="description">Description</label>
      <input
        type="text"
        id="description"
        value={post.description}
        className="description"
        onChange={(e) => {
          setStatus("loading");
          setDescription(e.target.value);
        }}
      />
      <div className="imgUrl">
        <label htmlFor="imgUrl">Image URL</label>
        <input
          type="url"
          id="imgUrl"
          value={imgUrl}
          onChange={(e) => setImgUrl(e.target.value)}
        />
      </div>
      <div className="featuredPost-container">
        <label htmlFor="featuredPost">Featured Post</label>
        <input
          type="checkbox"
          id="featuredPost"
          value={imgUrl}
          className="featuredPost"
          onChange={(e) => setFeaturedPost(e.target.checked)}
        />
      </div>
      <div className="catUpdate">
        <h4>Selected</h4>
        {categories?.map((category) => (
          <div
            key={category.id}
            to={`/posts/${category.slug}`}
            className="category"
            style={{ background: category.color.hex }}
          >
            {category.name}

            <div
              className="remove"
              onClick={() => handleDeleteCat(post.id, category.slug)}
            >
              <i className="fa-solid fa-minus" />
            </div>
          </div>
        ))}
      </div>
      <h4>Options</h4>
      <div className="catUpdate">
        {options?.map((category) => (
          <div
            key={category.value}
            to={`/posts/${category.value}`}
            className="category"
            style={{ background: category.color }}
          >
            {category.label}
            <div
              className="add"
              onClick={() => handleUpdateCat(post.id, category.value)}
            >
              <i className="fa-solid fa-plus" />
            </div>
          </div>
        ))}
      </div>
      <h4>Create New</h4>
      <form>
        <input
          type="text"
          name="name"
          value={newCat.name}
          onChange={handleChange}
        />
        <input type="color" name="color" onChange={handleChange} />
        <button onClick={handleNewCat}>Add New Category</button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {token && (
        <div className="edit-tools">
          <div className="updatePost" onClick={() => handleUpdate(post.id)}>
            <i className="fa-solid fa-check" />
          </div>
          <div className="editPost" onClick={handleEditToggle}>
            <i className="fa-solid fa-xmark fa-lg" title="Cancel" />
          </div>
        </div>
      )}
      <ContentEditor post={post} text={text} setText={setText} />
    </div>
  );
};

export default ArticleForm;
