import { GraphQLClient, gql } from "graphql-request";

export const graphcms = new GraphQLClient(process.env.REACT_APP_API)

const category =`
    id,
    name,
    slug,
    color { hex }
`
const comment =`
    id,
    name,
    email,
    comment,
    showEmail,
    createdAt,
    verified
`

const nextUser =`
    id,
    email,
    password,
    firstname,
    lastname,
    createdAt,
`

const post =`
    id,
    title,
    slug,
    imgUrl,
    text,
    description,
    createdAt,
    updatedAt,
    verified,
    featuredPost
`

export const QUERY_SLUG_CATEGORIES = gql`
   {
    categories(){
        name, 
        slug
    }
   } 
`

export const QUERY_POSTS = gql`
   query GetPosts($limit: Int!, $skip: Int!){
        posts(orderBy: updatedAt_DESC, first: $limit, skip: $skip){
            ${post}, 
            categories(){
                ${category}
            }
        }

        countConnection: postsConnection(stage: PUBLISHED) {
            aggregate {
              count
            }
        }
   } 
`

export const QUERY_POSTS_BY_CATEGORY = gql`
   query GetPostsByCategory($slug: String!, $limit: Int!, $skip: Int!){
        posts(
            orderBy: updatedAt_DESC,  
            first: $limit, 
            skip: $skip,
            where: {categories_some: {slug: $slug}}
        ){
            ${post}, 
            categories(){
                ${category}
            }
        }
        countConnection: postsConnection(
            stage: PUBLISHED,
            where: {categories_some: {slug: $slug}}
            ) {
            aggregate {
              count
            }
        }
   } 
`

// where: {_search: $slug, AND: {slug_contains: $slug}}
export const QUERY_SEARCH_POSTS = gql`
   query GetPostsByCategory($slug: String!, $limit: Int!, $skip: Int!){
        posts(
            orderBy: updatedAt_DESC,
            first: $limit, 
            skip: $skip,
            where: {_search: $slug}
        ){
            ${post}, 
            categories(){
                ${category}
            }
        }
        countConnection: postsConnection(
            stage: PUBLISHED,
            where: {categories_some: {slug: $slug}}
            ) {
            aggregate {
              count
            }
        }
   } 
`

export const QUERY_ONE_POST = gql`
   query GetOnePost($slug: String!, $limit: Int!, $skip: Int!){
        posts(where: {slug: $slug}) {
            ${post}, 
            categories(){
                ${category}
            }
            comments(
                orderBy: updatedAt_DESC,
                first: $limit, 
                skip: $skip,
            ){
                ${comment}
            }
        }
        countConnection: commentsConnection(where: {post: {slug: $slug}}) {
            aggregate {
              count
            }
        }
   } 
`


export const QUERY_USER = gql`
   query GetOneUser($email: String!){
    nextUser(where: {email: $email}) {
        ${nextUser}
      }
   } 
`
export const QUERY_CATEGORIES = gql`
query GetCategories(){
    categories(first: 500){
        name,
        slug,
        color {hex}
    }
}
`
