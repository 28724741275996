import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import { useMyContext } from "../context/store";
import { graphcms, QUERY_SLUG_CATEGORIES } from "../Graphql/Queries";
import netlifyIdentity from "netlify-identity-widget";
netlifyIdentity.on("init", (user) => {
  // console.log(user);
});

netlifyIdentity.init();
const Header = () => {
  const [categories, setCategories] = useState([]);
  const { token, setToken } = useMyContext();

  useEffect(() => {
    graphcms
      .request(QUERY_SLUG_CATEGORIES)
      .then((res) => setCategories(res.categories));
  }, []);

  function logout() {
    setToken(false);
    netlifyIdentity.logout();
  }
  function signIn(e) {
    e.preventDefault();

    netlifyIdentity.open();
  }
  return (
    <header>
      <input type="checkbox" id="menu-btn" hidden />
      <nav>
      <Link to="/" className="logo">
          Panda Love
        </Link>
        {token ? (
          <>
            <button onClick={logout} className="logout">
              <i className="fa-solid fa-right-from-bracket"></i>
            </button>
            <Link to="/newPost" className="newPost">
              <i className="fa-solid fa-plus" />
            </Link>
          </>
        ) : (
          <button onClick={signIn} className="login">
            <i className="fa-solid fa-right-to-bracket"></i>
          </button>
        )}

        
        <NavLinks categories={categories} />
        <label htmlFor="menu-btn" className="fa-solid fa-bars" />
      </nav>
    </header>
  );
};

export default Header;
