import "./contentEditor.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';

import Editor from 'ckeditor5-custom-build/build/ckeditor';

import parse from "html-react-parser"


const ContentEditor = ({text, setText}) => {


  return (
    <div className="main-container">
     <div className="editor">
        <CKEditor 
        editor={ Editor }
        name="editor"
        data={text}
        onChange={(event, editor) => {
            const data = editor.getData()
            setText(data)
        }} />
     </div >
     <div>
        <h2>Preview</h2>
        <span>{parse(text)}</span>
     </div>
    </div>
  );
};

export default ContentEditor;
