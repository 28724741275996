import React from "react";
import { Route, Routes } from "react-router";
import Header from "./componenets/Header";
import Footer from "./componenets/Footer";
import Home from "./pages/Home";
import PostsByCategory from "./pages/PostsByCategory";
import Search from "./componenets/Search";
import SearchResults from "./pages/SearchResults";
import Article from "./pages/Article";
import NotFound from "./componenets/NotFound";
import Loading from "./componenets/Loading";
import { useMyContext } from "./context/store";
import Pagination from "./componenets/Pagination";
import ScrollButton from "./componenets/ScrollButton";
import NewPost from "./pages/NewPost";



const App = () => {
  const { loading, totalPage, token } = useMyContext();

  return (
    <React.Fragment>
      {loading && <Loading />}
      <Header />
      {!token && <Search />}

      <main>
        {/* <h1 className="construction">
          Site Under Construction! Please excuse our mess!
        </h1> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/posts/:slug" element={<PostsByCategory />} />
          <Route path="/search/:slug" element={<SearchResults />} />
          <Route path="/article/:slug" element={<Article />} />
          <Route path="/newPost" element={<NewPost />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </main>

      <Pagination totalPage={totalPage} />
      <Footer />
      <ScrollButton />
    </React.Fragment>
  );
};

export default App;
