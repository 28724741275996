import React, { useContext, useState, useEffect } from 'react'
import netlifyIdentity from "netlify-identity-widget";


export const Store = React.createContext();

export const ContextProvider = ({children}) => {
    const [ loading, setLoading] = useState(false);
    const [ totalPage, setTotalPage] = useState(1);
    const [ token, setToken ] = useState(false);
    const [ email, setEmail ] = useState("");
    const [reload, setReload] = useState(false);
    const [ editToggle, setEditToggle ] = useState(true)
    const [ newPost, setNewPost ] = useState(false)
    const user = netlifyIdentity.currentUser();

    netlifyIdentity.on('close', ()=> reloadWindow())

   function reloadWindow(){
    window.location.reload()
   }
 useEffect(()=>{
  
}, [reload])

    useEffect(()=>{
      if (user){
       
        setEmail(user.email)
      } else {
        setEmail("")
      }
        // eslint-disable-next-line
    }, [user])
    
  useEffect(()=>{
    if (!user) {
       setToken(false);
    } else {
      
      setToken(true);
     }
     
    // eslint-disable-next-line
  }, [user])
  function handleEditToggle(){
    setEditToggle(!editToggle)
  }

  function handleNewPostToggle(){
    setNewPost(!newPost)
    
  }
    const value = {
        loading, setLoading,
        totalPage, setTotalPage,
        token, setToken, email, reload, setReload, editToggle, setEditToggle, handleEditToggle, handleNewPostToggle
    }
    return (
        <Store.Provider value= {value} >
            {children}
        </Store.Provider>
    )
}

export const useMyContext = () => useContext(Store);

