import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <span className='copyright'>Copyright 2023 Panda Love LLC</span>
      <span className='author'>Diane Larsen</span>
      <div className="footer-social">
        <Link to="https://www.facebook.com/groups/pandalovedev"><i className='fab fa-facebook-f'></i></Link>
        {/* <Link to="/"><i className='fab fa-youtube'></i></Link>
        <Link to="/"><i className='fab fa-twitter'></i></Link>
        <Link to="/"><i className='fab fa-instagram'></i></Link> */}
      </div>

    </footer>
  )
}

export default Footer
