import React from "react";
import { QUERY_ONE_POST } from "../Graphql/Queries";
import useQueryPosts from "../hooks/useQueryPosts";
import Post from "../componenets/Post";
import Comments from "../componenets/Comments";
import { useMyContext } from "../context/store";

const Article = () => {
    const { editToggle } = useMyContext();
    const { posts, error} = useQueryPosts({query: QUERY_ONE_POST});

    if(error) return <h2 style={{textAlign: 'center'}}>{error}</h2>;

    return (
        <div className="post-comment">
            {
                posts?.map(post => (
                    <React.Fragment key={post.id}>
                        <Post post={post}/>
                        {editToggle && <Comments comments={post?.comments}/>}
                    </React.Fragment>
                ))
            }
        </div>
    ) 
}

export default Article
