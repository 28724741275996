import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './context/store';
import { PicContextProvider } from "./context/picUploadContext";

import { DarkModeContextProvider } from "./context/darkModeContext";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <DarkModeContextProvider>

    <BrowserRouter>
    <PicContextProvider >
      <ContextProvider>


        <App />

      </ContextProvider>
      </PicContextProvider>
    </BrowserRouter>

    </DarkModeContextProvider>
  </React.StrictMode>
);


