import { useEffect, useState, useContext } from "react";
import { useMyContext } from "../context/store";
import ContentEditor from "../componenets/ContentEditor";
import { graphcms, CREATE_POST, PUBLISH_POST, UPDATE_POST_CATEGORY } from "../Graphql/Mutations";
import { Link } from "react-router-dom";
import slugify from "react-slugify";
import Option from "../componenets/Option";
import CreatableSelect from 'react-select/creatable';
import { PicContext } from "../context/picUploadContext";
import CloudinaryUploadWidget from "../Widget/CloudinaryUploadWidget";
import { QUERY_CATEGORIES } from "../Graphql/Queries";
import chroma from "chroma-js";
import { ConfirmContext } from "../context/ConfirmContext";


const NewPost = ({ post }) => {
  const { token, reload, setReload } = useMyContext();
  const { myWidget, imgURL } = useContext(PicContext);
  const { Confirm } = useContext(ConfirmContext);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [featuredPost, setFeaturedPost] = useState(false);
  const [selection, setSelection] = useState({ optionSelected: null });
  const [ colourOptions, setColourOptions] = useState([])
  const [ error, setError ] = useState(null)
  const [ status, setStatus ] = useState('')
console.log(imgUrl)

    if (status === "loading"){
      console.log("Not Yet Saved")
    } else if (status === "succsess"){
      console.log("Submitted")
    } else {
      console.log("No Change")
    }

    function imgClickHandler() {
      Confirm.open({
        title: "Post Image",
        message: "Would you like to Change or Remove your Post Image?",
        okText: "Change",
        cancelText: "Remove",
        onok: () => {
          myWidget.open();
        },
        oncancel: () => {
          console.log("canceled")
        },
      });
    }
    function handlePictureUpload() {
      setImgUrl(imgURL.imgUrl)
    }
  const colourStyles =  {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? data.color
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };
  
  useEffect(()=> {
    graphcms.request(QUERY_CATEGORIES)
    .then(res => {

      const catRes = res.categories
      const optionArr = catRes.map(function(obj) {
        obj['label'] = obj['name']; // Assign new key
        obj['value'] = obj['slug']; // Assign new key
        obj['color'] = obj['color']['hex']; // Assign new key
        delete obj['name']; // Delete old key
        delete obj['slug']; // Delete old key
        delete obj['color']['hex'] ; // Delete old key
        return obj;
      })

      setColourOptions(optionArr)
    })
    .catch(errors => {
      setError(errors)
    })
  },[])

  if (error)
    return <h2 style={{ textAlign: "center", color: "red" }}>{error}</h2>;

  function handleChange(selected) {
    setSelection({
      optionSelected: selected,
    });
    setStatus("loading");
  }



 
  async function handleCreate() {
    
    // console.log(selection)
    const catArry = selection.optionSelected.map(colorSlug => {
      const container = {};
      container["slug"] = colorSlug.value;
      return container
    })
    // console.log(catArry)
    const obj = {
      title: title,
      text: text,
      description: description,
      verified: false,
      imgUrl: imgUrl || "https://picsum.photos/200/300",
      featuredPost: featuredPost,
      slug: slugify(title),
    };
    const { createPost } = await graphcms.request(CREATE_POST, obj);
    catArry.map(async (cat) => {
     
      await graphcms.request(UPDATE_POST_CATEGORY, { id: createPost?.id, slug: cat.slug})
    })
    // await graphcms.request(UPDATE_POST_CATEGORY, { id: createPost?.id }, catArry);
    await graphcms.request(PUBLISH_POST, { id: createPost?.id });
    setStatus("success");
    setReload(!reload);
  }

  return (
    <div className="newPost-container">
      <div className="title">
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="description">
        <label htmlFor="description">Description:</label>
        <textarea
          type="text"
          id="description"
          maxLength={100}
          minLength={50}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="imgUrl">
        <label htmlFor="imgUrl">Image URL</label>
        <div>
        <input
          type="url"
          id="imgUrl"
          value={imgUrl}
          onChange={(e) => setImgUrl(e.target.value)}
        />
        <CloudinaryUploadWidget  setImgUrl={setImgUrl}/>
        </div>
        <div className="custom-file-upload">
          <div
            className="img-wrap"
            onClick={() => imgClickHandler()}
            title="Click to change Picture"
          >
            {imgUrl ? (
               <img alt="" src={imgUrl} />
              // <i className="fa-solid fa-upload img-upload"></i>
            ) : (
              <img alt="" src="https://res.cloudinary.com/dqjh46sk5/image/upload/v1684789663/Capture_hdquvk.png" height= "200px"/>
            )}
          </div>
          {imgURL && (
            <button onClick={handlePictureUpload}>Click to Confirm</button>
          )}
        </div>

      </div>
      <div className="featuredPost-container">
        <label htmlFor="featuredPost">Featured Post</label>
        <input
          type="checkbox"
          id="featuredPost"
          value={imgUrl}
          className="featuredPost"
          onChange={(e) => setFeaturedPost(e.target.checked)}
        />
      </div>
      <CreatableSelect
        options={colourOptions}
        isMulti
        closeMenuOnSelect={false}
 
        styles={colourStyles}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        allowSelectAll={true}
        value={selection.optionSelected}
      />
      {token && (
        <div className="edit-tools">
          <div className="createPost" onClick={handleCreate}>
            <i className="fa-solid fa-check" />
          </div>
          <Link className="cancelPost" to="/">
            <i className="fa-solid fa-xmark fa-lg" title="Cancel" />
          </Link>
        </div>
      )}
      <ContentEditor post={post} text={text} setText={setText} />
    </div>
  );
};

export default NewPost;
