import { GraphQLClient, gql } from "graphql-request";

export const graphcms = new GraphQLClient(process.env.REACT_APP_API, {
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
  },
});

const obj = `
    $name: String!,
    $email: String!,
    $comment: String!,
    $slug: String!,
    $showEmail: Boolean!,
    $verified: Boolean!
`;
const data = `
    name: $name, 
    email: $email, 
    comment: $comment,
    showEmail: $showEmail,
    verified: $verified,
    post: {
        connect: { slug: $slug }
    }
`;
const postObj = `
    $title: String!,
    $slug: String!,
    $text: String!,
    $description: String!,
    $verified: Boolean!,
    $imgUrl: String!,
    $featuredPost: Boolean!,
    
`;
const postData = `
    title: $title, 
    slug: $slug, 
    text: $text,
    verified: $verified,
    description: $description,
    imgUrl: $imgUrl,
    featuredPost: $featuredPost,
    
`;
// const catObj = `
//     $name: String!,
//     $slug: String!,
//     $hex: Hex!
// `;

// const catData = `
//     name: $name, 
//     slug: $slug, 
//     hex: $hex,    
// `;

export const CREATE_COMMENT = gql`
    mutation CreateComment(${obj}) {
        createComment(data: { ${data} }) {
            id
        }
    }
`;

export const UPDATE_COMMENT_APPROVAL = gql`
  mutation ($id: ID!) {
    updateComment(where: { id: $id }, data: { verified: true }) {
      id
      verified
    }
  }
`;
export const PUBLISH_COMMENT = gql`
  mutation PublishComment($id: ID!) {
    publishComment(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;
export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(where: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_POST = gql`
    mutation ($id: ID!, ${postObj}) {
        updatePost(where: { id: $id }, data: { ${postData} }) {
        id,
        verified,
        text
        }
    }
`;
export const PUBLISH_POST = gql`
  mutation PublishPost($id: ID!) {
    publishPost(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;
export const CREATE_POST = gql`
    mutation CreatePost(${postObj} ) 
    {
        createPost( 
          data: {${postData}}
    ) 
   
        {
            id
        }
    }
`;
export const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(where: { id: $id }) {
      id
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $slug: String!, $color: ColorInput!) {
    createCategory(data: { name: $name, slug: $slug, color: $color }) {
      id
    }
  }
`;
export const PUBLISH_CATEGORY = gql`
  mutation PublishCategory($id: ID!) {
    publishCategory(where: { id: $id }, to: PUBLISHED) {
      id
    }
  }
`;
export const UPDATE_POST_CATEGORY = gql`
  mutation ($id: ID!, $slug: String!) {
    updatePost(
      where: { id: $id }
      data: { categories: { connect: { where: { slug: $slug } } } }
    ) {
      id
    }
  }
`;
export const REMOVE_POST_CATEGORY = gql`
  mutation ($id: ID!, $slug: String!) {
    updatePost(
      where: { id: $id }
      data: { categories: { disconnect: { slug: $slug } } }
    ) {
      id
    }
  }
`;
