import { Link } from "react-router-dom";
import { useMyContext } from "../context/store";
import { graphcms, UPDATE_POST, PUBLISH_POST } from "../Graphql/Mutations";

const Posts = ({ posts }) => {
  const { token, setReload, reload } = useMyContext();

  async function handleApproval(
    id,
    title,
    text,
    description,
    imgUrl,
    featuredPost,
    slug
  ) {
    const obj = {
      id: id,
      title: title,
      text: text,
      description: description,
      verified: true,
      imgUrl: imgUrl,
      featuredPost: featuredPost,
      slug: slug,
    };
    await graphcms.request(UPDATE_POST, obj);
    await graphcms.request(PUBLISH_POST, { id: id });
    setReload(!reload);
  }
  return (
    <div className="posts-container">
      {posts?.map((post) => ((post.verified || token) && (
        <div key={post.id} className="posts-box">
          <div className="posts-img">
            <Link to={`/article/${post.slug}`}>
              <img
                src={post.imgUrl || "https://picsum.photos/200/300"}
                alt=""
              />
            </Link>
            <div className="categories">
              {post.categories?.map((category) => (
                <Link
                  key={category.id}
                  to={`/posts/${category.slug}`}
                  className="category"
                  style={{ background: category.color.hex }}
                >
                  {category.name}
                </Link>
              ))}
            </div>
            {token && !post.verified && (
              <div
                className="verified"
                onClick={() =>
                  handleApproval(
                    post.id,
                    post.title,
                    post.text,
                    post.description,
                    post.imgUrl,
                    post.featuredPost,
                    post.slug
                  )
                }
              >
                {" "}
                <i className="fa-solid fa-check" />{" "}
              </div>
            )}
          </div>
          
            <div className="posts-text">
              <div className="category-time">
                <div className="categories-list">
                {post.categories?.map((category) => (
                  <Link
                    key={category.id}
                    to={`/posts/${category.slug}`}
                    style={{ color: category.color.hex }}
                  >
                    {category.name}
                  </Link>
                ))}
                  </div>
                <span className="published-time">
                  {new Date(post.updatedAt).toDateString()}
                </span>
              </div>

              <Link to={`/article/${post.slug}`}>
                <h3>{post.title}</h3>
              </Link>
              <p className="excerpt">{post.description}</p>
            </div>
         
        </div>)
      ))}
    </div>
  );
};

export default Posts;
