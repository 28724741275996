import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  graphcms,
  CREATE_COMMENT,
  PUBLISH_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT_APPROVAL
} from "../Graphql/Mutations";
import { useMyContext } from "../context/store";

const Comments = ({ comments }) => {
  const { slug } = useParams();
  const [name, setName] = useState(localStorage.getItem("name") || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [comment, setComment] = useState("");
  const [save, setSave] = useState(true);
  const [show, setShow] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ verified, setVerified ] = useState(false)
  const { token, reload, setReload } = useMyContext();

  async function handleComment(e) {
    e.preventDefault();
    const obj = { name, email, comment, showEmail, slug, verified };

    if (save) {
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
    } else {
      localStorage.removeItem("name", name);
      localStorage.removeItem("email", email);
    }
    setLoading(true);
    const { createComment } = await graphcms.request(CREATE_COMMENT, obj);
    await graphcms.request(PUBLISH_COMMENT, { id: createComment?.id });
    setReload(!reload);
    setLoading(false);
  }
  async function handleApproval(id){
    
    await graphcms.request(UPDATE_COMMENT_APPROVAL, {id: id});
    await graphcms.request(PUBLISH_COMMENT, { id: id });
    setReload(!reload)
    setVerified(true)
    }
  async function handleDelete(id) {
    await graphcms.request(DELETE_COMMENT, { id: id });

    setReload(!reload);
  }

  function handleShow() {
    setShow(!show);
  }

  return (
    <div className="comments">
      <h2 onClick={handleShow}>Leave a Reply</h2>
      {show && (
        <form onSubmit={handleComment}>
          <input
            type="text"
            required
            placeholder="Name"
            autoComplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="email"
            required
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <textarea
            cols="30"
            rows="5"
            required
            placeholder="Comment"
            value={comment}
            autoComplete="off"
            onChange={(e) => setComment(e.target.value)}
          />

          <div className="check">
            <input
              type="checkbox"
              id="chk"
              onChange={(e) => setSave(e.target.checked)}
              checked={save}
            />
            <label htmlFor="chk">
              Save my email and name(do not click if on public computer)
            </label>

            <input
              type="checkbox"
              id="hide"
              onChange={(e) => setShowEmail(e.target.checked)}
              checked={showEmail}
            />
            <label htmlFor="hide">Show email on comment</label>
          </div>

          <button disabled={loading}>
            {loading ? `Loading...` : "Post Comment"}
          </button>
        </form>
      )}
      <div>
        {comments?.map((comment) => ((token || comment.verified) && (
          <div className="content" key={comment.id}>
            {comment.showEmail ? (
              <strong>
                <i className="fa-solid fa-user"></i>
                {comment.name} ({comment.email}) -{" "}
                <span>{new Date(comment.createdAt).toDateString()}</span>
              </strong>
            ) : (
              <strong>
                <i className="fa-solid fa-user"></i>
                {comment.name} -{" "}
                <span>{new Date(comment.createdAt).toDateString()}</span>
              </strong>
            )}
            <p>{comment.comment}</p>
            <div className="modify">
              {(token && !comment.verified) && <div className="approve" onClick={() => handleApproval(comment.id)}>
                <i className="fa-solid fa-check" />
              </div>}
              <div className="delete" onClick={() => handleDelete(comment.id)}>
                <i className="fa-solid fa-trash" />
              </div>
            </div>
          </div>)
        ))}
      </div>
    </div>
  );
};

export default Comments;
