import React, { useEffect } from "react";
import { useMyContext } from "../context/store";
import netlifyIdentity from "netlify-identity-widget";
import ArticleForm from "./ArticleForm";
import parse from "html-react-parser";
import { graphcms, DELETE_POST } from "../Graphql/Mutations";
import { useNavigate } from "react-router-dom";

const Post = ({ post }) => {
  const navigate = useNavigate();
  const { token, reload, setReload, editToggle, handleEditToggle } =
    useMyContext();

  useEffect(() => {}, [reload]);

  netlifyIdentity.on("login", () => {
    setReload(!reload);
  });

  async function handleDeletePost(id) {
    await graphcms.request(DELETE_POST, { id: id });

    navigate("/");
  }
  return (
    <article>
      {editToggle ? (
        <>
          <div className="post-container">
            <div className="article">
              <div className="article-image">
                <img src={post.imgUrl} alt="Featured"></img>
              </div>
              <div className="title-tools">
                <h1 className="title">{post.title}</h1>
                {token && (
                  <div className="edit-tools">
                    <div
                      className="deletePost"
                      onClick={() => handleDeletePost(post.id)}
                    >
                      <i className="fa-solid fa-trash" />
                    </div>
                    <div className="editPost" onClick={handleEditToggle}>
                      <i className="fa-solid fa-pen" />
                    </div>
                  </div>
                )}
              </div>
              <div className="time-categories">
                <div className="published-time">
                  <i className="far fa-clock"></i>
                  <span>{new Date(post.updatedAt).toDateString()}</span>
                </div>

                <div className="categories">
                  {post.categories?.map((category) => (
                    <div
                      key={category.id}
                      to={`/posts/${category.slug}`}
                      className="category"
                      style={{ background: category.color.hex }}
                    >
                      {category.name}
                    </div>
                  ))}
                </div>
              </div>
              <span className="content">

              {parse(post.text)}
              </span>
            </div>
          </div>
        </>
      ) : (
        <ArticleForm post={post} />
      )}
    </article>
  );
};

export default Post;
