import { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"
import { graphcms } from "../Graphql/Queries";
import { useMyContext } from "../context/store";


const useQueryPosts = ({query, limit = 6}) => {
    const { slug } = useParams();

    const { search, pathname } = useLocation();

    const page = Number(new URLSearchParams(search).get('page')) || 1;
    const skip = (page - 1) * limit;

    const [ posts, setPosts ] = useState([])
    
    const [ error, setError ] = useState(null)
    const [ status, setStatus ] = useState('')

    const { setLoading, setTotalPage, reload } = useMyContext();
    useEffect(() => {
        setTotalPage(1)
        // eslint-disable-next-line
    }, [pathname])

    useEffect(() => {
        setStatus('start')
        setLoading(true)
        graphcms.request(query, {slug, limit, skip})
        .then(res => {
            setStatus('success')
            const count = res?.countConnection?.aggregate?.count || 0;
            setTotalPage(Math.ceil(count / limit))
            setPosts(res.posts)
            setError(null)
        })
        .catch(error => {
            setStatus('error')
            setTotalPage(1)
            setError(error.message)
            setPosts([])
        })
        .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [query, slug, limit, skip, reload])

    return { posts, error, status}
}

export default useQueryPosts
