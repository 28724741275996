import React from 'react'

const NotFound = () => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src="https://res.cloudinary.com/dqjh46sk5/image/upload/v1683734406/5203299_yg55o3.jpg" alt="404 | NotFound" style={{margin: '20px 0', maxWidth: '95%', maxHeight: '65vh', objectFit: 'cover', mixBlendMode: "darken"}}/>
    </div>
  )
}

export default NotFound
